import './App.css';
import { useState, useEffect } from 'react';
import CB_TCI from './assets/cb-tci.png'

import CB_1_STREAM_ORDER from './assets/cb-1-stream-order.png';
import CB_5_FLOW_ACCUMULATION from './assets/cb-5-flow-accumulation.png';
import CB_6_FLOW_DIRECTION from './assets/cb-6-flow-direction.png';
import CB_8_STI from './assets/cb-8-sti.png';
import CB_9_SPI from './assets/cb-9-spi.png';

function App() {

  const [alphas, setAlphas] = useState({
    flow_accumulation: 0.7,
    flow_direction: 0.5,
    sti: 0.6,
    spi: 0.7,
  });

  const onProgressClick = (ev, key) => {
    const nev = ev.nativeEvent;
    if (nev.buttons === 0) {
      return false;
    }
    const change = {};
    change[key] = nev.offsetX / nev.target.clientWidth;
    setAlphas({...alphas, ...change });
  }

  return (
    <div className="App">
      <div className='header'>
        <img className='true-color-image' src={CB_TCI} />
        <div className="metadata">
          <div>
            <h2>
              Camp Bird Water Management
            </h2>
            <ul>
              <li><span className="key">Site:</span> <a target="_blank" rel="noopener" href="https://goo.gl/maps/2e7tgCLXBv4Cc8uLA">(37.97, -107.73)</a></li>
              <li><span className="key">Date:</span> 2023/03/20</li>
              <li><span className="key">Source:</span> <a href="https://sentinel.esa.int/web/sentinel/home">Sentinel-2</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div className='results'>
        <div className='result'>
          <img src={CB_1_STREAM_ORDER} />
          <progress className="hidden-slot-placeholder" value=".7"></progress>
          <div className='result-data'>
            <div className="name">Stream order</div>
          </div>
        </div>

        <div className='result'>
          <img style={ { opacity: alphas.flow_accumulation } } className='data-layer' src={CB_5_FLOW_ACCUMULATION} />
          <img src={CB_TCI} />
          <progress value={alphas.flow_accumulation}
                    onMouseMove={(ev) => onProgressClick(ev, 'flow_accumulation')}
                    ></progress>
          <div className='result-data'>
            <div className="name">Flow accumulation</div>
          </div>
        </div>

        <div className='result'>
          <img style={ { opacity: alphas.flow_direction } } className='data-layer' src={CB_6_FLOW_DIRECTION} />
          <img src={CB_TCI} />
          <progress value={alphas.flow_direction}
                    onMouseMove={(ev) => onProgressClick(ev, 'flow_direction')}
                    ></progress>
          <div className='result-data'>
            <div className="name">Flow direction</div>
          </div>
        </div>

        <div className='result'>
          <img style={ { opacity: alphas.sti } } className='data-layer' src={CB_8_STI} />
          <img src={CB_TCI} />
          <progress value={alphas.sti}
                    onMouseMove={(ev) => onProgressClick(ev, 'sti')}
                    ></progress>
          <div className='result-data'>
            <div className="name">Stream transportation index - STI</div>
          </div>
        </div>

        <div className='result'>
        <img style={ { opacity: alphas.spi } } className='data-layer' src={CB_9_SPI} />
          <img src={CB_TCI} />
          <progress value={alphas.spi}
                    onMouseMove={(ev) => onProgressClick(ev, 'spi')}
                    ></progress>
          <div className='result-data'>
            <div className="name">Stream power index - SPI</div>
          </div>
        </div>

      </div>

    </div>
  );
}

export default App;
